export const ContractMixin = {
    methods: {
        getContractType(contract) {
            console.log(contract.type);
            let types = {
                "simple": "Maintenance simple",
                "preventive": "Maintenance préventive",
                "controle": "Contrôle obligatoire",
                "assurance": "Assurance",
            }
            if (contract.type in types) {
                return types[contract.type];
            } else {
                return contract.type;
            }
        }
    },
}