<template>
  <v-dialog width="600" :retain-focus="false" v-model="site_dialog" :close-on-content-click="false">
    <template v-slot:activator="{on, attrs}">
      <v-btn
           :class="nomargin ? '' : 'mt-6'"
          color="primary"
          text
          v-bind="attrs"
          v-on="on"
      >
        {{ site_filter_display_text }}
      </v-btn>
    </template>
    <v-card elevation="0" width="600">
      <v-app-bar color="primary">
        <v-toolbar-title class="white--text">Sélectionner le site</v-toolbar-title>
      </v-app-bar>
      <v-treeview
          :items="sites_tree"
          v-bind:value="value"
          v-on:input="handleInput"
          dense
          item-text="name" item-key="id" item-children="sites"
          selectable multiple-active selection-type="independent" v-model="sites_selected"></v-treeview>
      <v-card-actions>
        <v-btn color="red" @click="cleanSiteFilter" text>Effacer le filtre</v-btn>
        <v-btn @click="site_dialog = false" text color="primary">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'SiteTreeSelector',
  mixins: [],
  props: {
    value: Array,
    nomargin: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      sites_selected: [],
    }
  },
  computed: {
    site_filter_display_text() {
      if (this.sites_selected.length === 0) {
        return "Sélectionner les sites";
      }
      if (this.sites_selected.indexOf("") !== -1) {
        return "Tous les sites";
      }
      return `${this.sites_selected.length} site(s) sélectionné(s)`;
    },
    site_dialog: {
      get() {
        return this.$store.getters["assets/getSiteDialog"];
      },
      set(value) {
        return this.$store.commit("assets/setSiteDialog", value)
      }
    },
    sites_tree() {
      return [{id: "", name: "Tous les sites"}, ...this.$store.getters["sites/sitesTree"]];
    },
  },
  methods: {
    cleanSiteFilter() {
      this.sites_selected = [];
    },
    displayAllSites() {
      this.sites_selected = [""];
    },
    handleInput(e) {
      this.$emit("input", e);
    }
  },
    watch: {
      value(new_value) {
        this.sites_selected = new_value;
      }
  },
  beforeDestroy() {
    this.sites_selected = [];
    this.site_dialog = false;
  }

}
</script>